import { useAuth } from '@app/react-cognito';
import {
  faCheck,
  faEllipsisVertical,
  faKey,
  faPencil,
  faUserCheck,
  faUserSlash,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
  message,
} from 'antd';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { Columns } from '../../components/admin/Table';
import TableWithDrawer from '../../components/admin/TableWithDrawer';
import PageHeader from '../../components/base/PageHeader';
import KnowledgeBaseSelect from '../../components/form/KnowledgeBaseSelect';
import RoleSelect from '../../components/form/RoleSelect';
import useCompany from '../../context/company/useCompany';
import {
  GetUsersQuery,
  GetUsersQueryVariables,
  Role,
  User,
  UserStatus,
  useDeleteUserMutation,
  useDisableUserMutation,
  useEnableUserMutation,
  useGetUsersQuery,
  useInviteUserMutation,
  useUpdateUserMutation,
} from '../../graphql/schema';
import confirmModal from '../../helper/confirmModal';
import formItemProps from '../../helper/formItemProps';
import useFormModal from '../../helper/useFormModal';

const columns: Columns<User> = {
  email: 'E-Mail',
  name: {
    label: 'Name',
    render: (_, item) => `${item.firstName ?? ''} ${item.lastName ?? ''}`,
  },
  status: {
    label: 'Status',
    render: (value) => {
      if (value === UserStatus.ARCHIVED) return 'Archiviert';
      if (value === UserStatus.COMPROMISED) return 'Kompromittiert';
      if (value === UserStatus.CONFIRMED) return 'Bestätigt';
      if (value === UserStatus.EXTERNAL_PROVIDER) return 'Externe Anmeldung';
      if (value === UserStatus.FORCE_CHANGE_PASSWORD)
        return 'Passwortänderung nötig';
      if (value === UserStatus.RESET_REQUIRED) return 'Zurücksetzen nötig';
      if (value === UserStatus.UNCONFIRMED) return 'Unbestätigt';
      if (value === UserStatus.UNKNOWN) return 'Unbekannt';
      return '-';
    },
  },
  role: {
    label: 'Rolle',
    render: (role) => {
      if (role === Role.USER) return 'Benutzer';
      if (role === Role.COMPANY_ADMIN) return 'Firmenadministrator';
      if (role === Role.SYSTEM_ADMIN) return 'Systemadministrator';
      return '-';
    },
  },
  enabled: {
    label: 'Zugriff erlaubt',
    render: (value) =>
      value ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : (
        <FontAwesomeIcon icon={faUserSlash} />
      ),
  },
  createdAt: {
    label: 'Erstellt am',
    render: (value) => dayjs(value).format('DD.MM.YYYY'),
  },
};

interface FormFields {
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
  knowledgeBaseIds: string[];
}

const Users = () => {
  const { company } = useCompany();
  const { groups } = useAuth();

  const [changePasswordModal, openChangePasswordModal] = useFormModal<{
    password: string;
  }>({
    title: 'Passwort ändern',
    okText: 'Ändern',
    cancelText: 'Abbrechen',
    renderForm: (form, formProps) => (
      <Form form={form} layout="vertical" {...formProps}>
        <Form.Item name="password" label="Passwort" required>
          <Input.Password />
        </Form.Item>
      </Form>
    ),
  });

  const [invite] = useInviteUserMutation();
  const [disable] = useDisableUserMutation();
  const [enable] = useEnableUserMutation();
  const [remove] = useDeleteUserMutation();
  const [update] = useUpdateUserMutation();

  const handleInvite = useCallback(
    async (values: FormFields) => {
      await invite({
        variables: {
          dto: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role,
            knowledgeBaseIds: values.knowledgeBaseIds,
          },
        },
        refetchQueries: ['GetUsers'],
      });
    },
    [invite],
  );

  const handleDisable = useCallback(
    (id: string) => async () => {
      if (
        await confirmModal({
          title: 'Benutzer reaktivieren',
          okText: 'Reaktivieren',
          cancelText: 'Abbrechen',
          content:
            'Der Benutzer hat damit keinen Zugriff mehr, bis sein Konto reaktiviert wurde',
        })
      ) {
        await disable({
          variables: {
            id,
          },
          refetchQueries: ['GetUsers'],
        });
        message.success('Der Benutzer wurde deaktiviert');
      }
    },
    [disable],
  );

  const handleEnable = useCallback(
    (id: string) => async () => {
      if (
        await confirmModal({
          title: 'Benutzer reaktivieren',
          okText: 'Reaktivieren',
          cancelText: 'Abbrechen',
          content: 'Der Benutzer erhält hierdurch wieder Zugriff',
        })
      ) {
        await enable({
          variables: {
            id,
          },
          refetchQueries: ['GetUsers'],
        });
        message.success('Der Benutzer wurde reaktiviert');
      }
    },
    [enable],
  );

  const handleSetPassword = useCallback(
    (id: string) => async () => {
      const result = await openChangePasswordModal();
      if (result?.password) {
        await update({
          variables: {
            id,
            dto: {
              password: result.password,
            },
          },
        });
        message.success('Das Passwort des Benutzers wurde geändert');
      }
    },
    [update, openChangePasswordModal],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
        refetchQueries: ['GetUsers'],
      });
    },
    [remove],
  );

  const handleUpdate = useCallback(
    async (id: string, values: FormFields) => {
      await update({
        variables: {
          id,
          dto: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role,
            knowledgeBaseIds: values.knowledgeBaseIds,
          },
        },
        refetchQueries: ['GetUsers'],
      });
    },
    [update],
  );

  const formRenderer = (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('email', 'E-Mail')}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps('firstName', 'Vorname')}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps('lastName', 'Nachname')}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...formItemProps('role', 'Rolle')}>
              <RoleSelect
                showSystemAdmin={groups.includes(Role.SYSTEM_ADMIN)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              {...formItemProps(
                'knowledgeBaseIds',
                'Zugriff auf Wissensdatenbanken',
              )}
            >
              <KnowledgeBaseSelect />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Card>
      <PageHeader
        breadcrumbs={[
          {
            label: company?.name ?? 'Firma',
          },
          {
            label: 'Benutzer',
          },
        ]}
      />

      <TableWithDrawer<FormFields, User, GetUsersQuery, GetUsersQueryVariables>
        query={useGetUsersQuery}
        columns={columns}
        editFormRenderer={formRenderer}
        createFormRenderer={formRenderer}
        singleItemTitle="Benutzer"
        onCreate={handleInvite}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        rowActions={(item, editRow) => (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'change',
                  label: (
                    <Space>
                      <FontAwesomeIcon icon={faPencil} />
                      Ändern
                    </Space>
                  ),
                  onClick: editRow,
                },
                {
                  key: 'setPassword',
                  label: (
                    <Space>
                      <FontAwesomeIcon icon={faKey} />
                      Passwort setzen
                    </Space>
                  ),
                  onClick: handleSetPassword(item.id),
                },
                ...(item.enabled
                  ? [
                      {
                        key: 'disable',
                        label: (
                          <Space>
                            <FontAwesomeIcon icon={faUserSlash} />
                            Deaktivieren
                          </Space>
                        ),
                        onClick: handleDisable(item.id),
                      },
                    ]
                  : [
                      {
                        key: 'enable',
                        label: (
                          <Space>
                            <FontAwesomeIcon icon={faUserCheck} />
                            Reaktivieren
                          </Space>
                        ),
                        onClick: handleEnable(item.id),
                      },
                    ]),
              ],
            }}
            trigger={['click']}
          >
            <Button type="text">
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </Button>
          </Dropdown>
        )}
      />
      {changePasswordModal}
    </Card>
  );
};

export default Users;
