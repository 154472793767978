import { Card, Col, ColorPicker, Form, FormInstance, Input, Row } from 'antd';
import React, { useCallback } from 'react';
import { Columns } from '../../components/admin/Table';
import TableWithDrawer from '../../components/admin/TableWithDrawer';
import PageHeader from '../../components/base/PageHeader';
import FileStringUpload from '../../components/form/FileStringUpload';
import GatewaySelect from '../../components/form/GatewaySelect';
import {
  Company,
  GetCompaniesQuery,
  GetCompaniesQueryVariables,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompaniesQuery,
  useUpdateCompanyMutation,
} from '../../graphql/schema';
import formItemProps from '../../helper/formItemProps';

const columns: Columns<Company> = {
  name: 'Name',
};

interface FormFields {
  name: string;
  description: string;
  facts: string;
  logo: string;
  primaryColor: string;
  primaryColorText: string;
  secondaryColor: string;
  defaultGatewayId?: string | null;
}

const Companies = () => {
  const [create] = useCreateCompanyMutation();
  const [update] = useUpdateCompanyMutation();
  const [remove] = useDeleteCompanyMutation();

  const handleCreate = useCallback(
    async (values: FormFields) => {
      await create({
        variables: {
          dto: {
            name: values.name,
            config: {
              description: values.description,
              facts: values.facts,
              logo: values.logo,
              primaryColor: values.primaryColor,
              primaryColorText: values.primaryColorText,
              secondaryColor: values.secondaryColor,
              defaultGatewayId: values.defaultGatewayId,
            },
          },
        },
      });
    },
    [create],
  );

  const handleUpdate = useCallback(
    async (id: string, values: FormFields) => {
      await update({
        variables: {
          id,
          dto: {
            name: values.name,
            config: {
              description: values.description,
              facts: values.facts,
              logo: values.logo,
              primaryColor: values.primaryColor,
              primaryColorText: values.primaryColorText,
              secondaryColor: values.secondaryColor,
              defaultGatewayId: values.defaultGatewayId,
            },
          },
        },
      });
    },
    [update],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
      });
    },
    [remove],
  );

  const formRenderer = (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('name', 'Name', true)}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('description', 'Beschreibung', false)}>
              <Input.TextArea placeholder="Beschreibung" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('facts', 'Fakten', false)}>
              <Input.TextArea placeholder="Fakten zur Firma (z.B. Anzahl der Mitarbeiter, ...)" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item {...formItemProps('logo', 'Logo', true)}>
          <FileStringUpload />
        </Form.Item>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              {...formItemProps('primaryColor', 'Primäre Farbe', true)}
              style={{ marginBottom: 10 }}
              getValueFromEvent={(color) => {
                return `#${color.toHex()}`;
              }}
            >
              <ColorPicker showText />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              {...formItemProps('primaryColorText', 'Primäre Textfarbe', true)}
              style={{ marginBottom: 10 }}
              getValueFromEvent={(color) => {
                return `#${color.toHex()}`;
              }}
            >
              <ColorPicker showText />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              {...formItemProps('secondaryColor', 'Hintergrundfarbe', true)}
              style={{ marginBottom: 10 }}
              getValueFromEvent={(color) => {
                return `#${color.toHex()}`;
              }}
            >
              <ColorPicker showText />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              {...formItemProps('defaultGatewayId', 'Standard Gateway', false)}
            >
              <GatewaySelect />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Card>
      <PageHeader
        breadcrumbs={[
          {
            label: 'Firmen',
            link: '#',
          },
        ]}
      />

      <TableWithDrawer<
        FormFields,
        Company,
        GetCompaniesQuery,
        GetCompaniesQueryVariables
      >
        query={useGetCompaniesQuery}
        columns={columns}
        editFormRenderer={formRenderer}
        singleItemTitle="Firma"
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        prepareFormValues={(item) => ({
          name: item.name ?? '',
          description: item.config.description ?? '',
          facts: item.config.facts ?? '',
          logo: item.config.logo ?? '',
          primaryColor: item.config.primaryColor ?? '',
          primaryColorText: item.config.primaryColorText ?? '',
          secondaryColor: item.config.secondaryColor ?? '',
          defaultGatewayId: item.config.defaultGatewayId,
        })}
        drawerWidth={1000}
      />
    </Card>
  );
};

export default Companies;
