import { Button, Card, Form, Space, Table, notification } from 'antd';
import { useCallback, useState } from 'react';
import {
  File as ApiFile,
  TaskDefinition,
  useDeleteTaskDefinitionFileMutation,
} from '../../graphql/schema';
import confirmModal from '../../helper/confirmModal';
import { getFileNameFromFileId } from '../../helper/files';
import uploadFormFiles from '../../helper/uploadFormFiles';
import Upload from '../form/Upload';

interface Props {
  taskDefinition?: TaskDefinition;
  files: ApiFile[];
  reloadFiles: () => Promise<any>;
  filesLoading: boolean;
}

interface FormFields {
  files: File[];
}

const TaskDefinitionFiles = ({
  taskDefinition,
  files,
  reloadFiles,
  filesLoading,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [deleteFile] = useDeleteTaskDefinitionFileMutation();

  const [form] = Form.useForm<FormFields>();

  const handleUpload = useCallback(
    async (formFields: FormFields) => {
      if (!taskDefinition || loading) return;

      setLoading(true);

      await uploadFormFiles(
        'taskDefinition',
        formFields,
        taskDefinition.fileUploadInfo,
      );

      form.setFieldsValue({
        files: [],
      });

      await reloadFiles();

      setLoading(false);
    },
    [taskDefinition, form, reloadFiles, loading],
  );

  const handleDeleteFile = useCallback(
    (id: string) => {
      return async () => {
        if (!taskDefinition) return;

        if (
          await confirmModal({
            title: 'Datei löschen',
            content:
              'Die Datei wird endgültig gelöscht und kann nicht wiederhergestellt werden!',
            okText: 'Löschen',
            cancelText: 'Abbrechen',
          })
        ) {
          await deleteFile({
            variables: {
              taskDefinitionId: taskDefinition.id,
              fileId: id,
            },
          });
          notification.success({
            message: 'Die Datei wurde gelöscht.',
          });
          reloadFiles();
        }
      };
    },
    [taskDefinition, deleteFile, reloadFiles],
  );

  return (
    <Card>
      <Table
        columns={[
          {
            key: 'contentType',
            dataIndex: 'contentType',
            title: '',
            width: 100,
            render: (contentType: string | undefined, item: ApiFile) =>
              contentType?.startsWith('image/') ? (
                <img
                  src={item.thumbnailUrl}
                  alt=""
                  className="h-[40px] rounded"
                />
              ) : (
                ''
              ),
          },
          {
            key: 'fileName',
            dataIndex: 'fileName',
            title: 'Name',
            render: (fileName) => getFileNameFromFileId(fileName),
          },
          {
            key: 'size',
            dataIndex: 'size',
            title: 'Dateigröße',
            render: (size) =>
              size / 1024 < 1024
                ? `${(size / 1024).toFixed(1)} KB`
                : `${(size / 1024 / 1024).toFixed(1)} MB`,
          },
          {
            key: 'actions',
            title: '',
            dataIndex: 'id',
            align: 'right',
            render: (id) => (
              <Button danger onClick={handleDeleteFile(id)}>
                Löschen
              </Button>
            ),
          },
        ]}
        dataSource={files}
        loading={filesLoading}
        pagination={false}
      />
      <Form form={form} className="mt-2" onFinish={handleUpload}>
        <Space direction="horizontal" className="items-start">
          <Form.Item noStyle name="files">
            <Upload maxCount={20} />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Hochladen
          </Button>
        </Space>
      </Form>
    </Card>
  );
};

export default TaskDefinitionFiles;
