import './sentry';

import React, { useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import dayjs from 'dayjs';
import { COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID } from './constants';
import 'dayjs/locale/de';
import { AuthProvider } from '@app/react-cognito';
import { ConfigProvider, ThemeConfig } from 'antd';
import locale from 'antd/es/locale/de_DE';
import ApiProvider from './context/api/apiProvider';
import CompanyProvider from './context/company/companyProvider';
import FeedbackProvider from './context/feedback/feedbackProvider';
import ThemeProvider from './context/theme/themeProvider';
import useTheme from './context/theme/useTheme';

dayjs.locale('de-DE');

const Root = () => {
  const { colors } = useTheme();

  const theme = useMemo(
    () =>
      ({
        token: {
          colorPrimary: colors.primary,
          colorSuccess: '#52c41a',
          colorLink: '#222',
          borderRadius: 2,
        },
        components: {
          Layout: {
            headerBg: colors.primary,
            siderBg: '#fff',
          },
          Menu: {
            itemActiveBg: '#CCE9FF',
            itemSelectedBg: '#CCE9FF',
          },
        },
      }) satisfies ThemeConfig,
    [colors],
  );

  return (
    <ConfigProvider locale={locale} theme={theme}>
      <AuthProvider
        clientId={COGNITO_CLIENT_ID}
        userPoolId={COGNITO_USER_POOL_ID}
        language="deFormal"
      >
        <FeedbackProvider>
          <ApiProvider>
            <CompanyProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CompanyProvider>
          </ApiProvider>
        </FeedbackProvider>
      </AuthProvider>
    </ConfigProvider>
  );
};

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Root />
    </ThemeProvider>
  </React.StrictMode>,
);
