import {
  faFile,
  faFolder,
  faFolderPlus,
  faTrash,
  faUpload,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  Progress,
  Table,
  TableColumnsType,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import PageHeader from '../../components/base/PageHeader';
import useCompany from '../../context/company/useCompany';
import {
  useCreateKnowledgeBaseFolderMutation,
  useDeleteKnowledgeBaseFileMutation,
  useKnowledgeBaseDirectoryQuery,
} from '../../graphql/schema';
import confirmModal from '../../helper/confirmModal';
import useFormModal from '../../helper/useFormModal';

interface DataType {
  id: string;
  name: any;
  size?: number | null;
  type: 'folder' | 'file';
  url?: string | null;
  actions?: any;
}

const KnowledgeBase = () => {
  const { id: knowledgeBaseId } = useParams() as { id: string };
  const { company } = useCompany();

  const [searchParams, setSearchParams] = useSearchParams();

  const path = searchParams.get('path') ?? undefined;

  const { data, loading, refetch } = useKnowledgeBaseDirectoryQuery({
    variables: {
      knowledgeBaseId,
      path,
    },
  });

  const [deleteFile] = useDeleteKnowledgeBaseFileMutation();
  const [createFolder] = useCreateKnowledgeBaseFolderMutation();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [createFolderModal, openCreateFolderModal] = useFormModal<{
    name: string;
  }>({
    title: 'Ordner erstellen',
    okText: 'Erstellen',
    cancelText: 'Abbrechen',
    renderForm: (form, formProps) => (
      <Form form={form} layout="vertical" {...formProps}>
        <Form.Item name="name" label="Name" required>
          <Input />
        </Form.Item>
      </Form>
    ),
  });

  const handleOpenFolder = useCallback(
    (p?: string) => {
      return () => {
        setSearchParams({
          path: p ?? '',
        });
      };
    },
    [setSearchParams],
  );

  const handleDelete = useCallback(
    (id: string, name: string) => {
      return async () => {
        if (
          await confirmModal({
            title: 'Löschen',
            type: 'warning',
            okText: 'Löschen',
            cancelText: 'Abbrechen',
            content: name,
          })
        ) {
          await deleteFile({
            variables: {
              id,
              knowledgeBaseId,
            },
            refetchQueries: ['knowledgeBaseDirectory'],
          });
        }
      };
    },
    [deleteFile, knowledgeBaseId],
  );

  const handleCreateFolder = useCallback(async () => {
    const values = await openCreateFolderModal();
    if (values) {
      await createFolder({
        variables: {
          name: values.name,
          path,
          knowledgeBaseId,
        },
        refetchQueries: ['knowledgeBaseDirectory'],
      });
    }
  }, [openCreateFolderModal, path, createFolder, knowledgeBaseId]);

  const columns: TableColumnsType<DataType> = useMemo(
    () => [
      {
        key: 'thumbnail',
        render: (_, record) => {
          if (record.type === 'folder')
            return <FontAwesomeIcon icon={faFolder} />;
          return <FontAwesomeIcon icon={faFile} />;
        },
        width: 20,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) =>
          record.type === 'folder' ? (
            <span
              className="cursor-pointer"
              onClick={handleOpenFolder(record.id)}
            >
              {text}
            </span>
          ) : (
            <a href={record.url ?? ''} target="_blank">
              {text}
            </a>
          ),
      },
      {
        title: 'Größe',
        dataIndex: 'size',
        key: 'size',
        render: (size) => {
          if (!size) return null;
          return size / 1024 < 1024
            ? `${(size / 1024).toFixed(1)} KB`
            : `${(size / 1024 / 1024).toFixed(1)} MB`;
        },
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        render: (actions, record) =>
          actions ?? (
            <div className="space-x-2">
              <Button
                type="text"
                onClick={handleDelete(record.id, record.name)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          ),
        width: 20,
      },
    ],
    [handleOpenFolder, handleDelete],
  );

  const handleUploadChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} wurde hochgeladen`);
    }

    if (!info.fileList.find((f) => f.status !== 'done')) {
      // All done
      refetch();
      setFileList([]);
    } else {
      setFileList(info.fileList);
    }
  };

  const uploadProps: UploadProps = {
    fileList,
    showUploadList: false,
    action: data?.knowledgeBaseDirectory.fileUploadInfo.url,
    onChange: handleUploadChange,
    multiple: true,
    data: (file) => {
      return {
        ...data?.knowledgeBaseDirectory.fileUploadInfo.fields,
        'Content-Type': file.type,
      };
    },
  };

  return (
    <Card className="mb-5">
      <div className="flex flex-row justify-between mb-2">
        <div>
          <PageHeader
            breadcrumbs={[
              {
                label: company?.name ?? 'Firma',
              },
              {
                label: 'Wissensdatenbanken',
                link: '/companyAdmin/knowledgeBases',
              },
              {
                label: data?.knowledgeBase.name,
                link: '#',
              },
            ]}
          />
          <Breadcrumb
            items={[
              {
                title: data?.knowledgeBase.name ?? '',
                onClick: handleOpenFolder(),
              },
              ...(path
                ?.split('/')
                .filter((p) => `${p}`.trim() !== '')
                .map((p, index) => {
                  const subPath = path
                    ?.split('/')
                    .filter((_, i) => i <= index)
                    .join('/');

                  return {
                    title: p,
                    onClick: handleOpenFolder(`${subPath}/`),
                  };
                }) ?? []),
            ]}
            className="[&_.ant-breadcrumb-link]:cursor-pointer"
          />
        </div>
        <div className="flex flex-row space-x-10 items-center">
          <div className="space-x-2 flex flex-row items-center">
            {/* SYNC STATE */}
          </div>

          <div className="space-x-2">
            <Button onClick={handleCreateFolder}>
              <FontAwesomeIcon icon={faFolderPlus} />
              Ordner erstellen
            </Button>
            <Upload {...uploadProps}>
              <Button>
                <FontAwesomeIcon icon={faUpload} />
                Hochladen
              </Button>
            </Upload>
          </div>
        </div>
      </div>

      <Upload.Dragger
        openFileDialogOnClick={false}
        {...uploadProps}
        className="[&_.ant-upload]:!p-0 [&>div]:!border-0"
      >
        <Table<DataType>
          pagination={false}
          loading={loading}
          columns={columns}
          dataSource={[
            ...fileList.map(
              (file) =>
                ({
                  id: file.uid,
                  name: (
                    <div className="space-x-2">
                      <Progress
                        type="circle"
                        trailColor={file.status}
                        percent={file.percent}
                        strokeWidth={20}
                        size={14}
                        format={(number) => `${number}%`}
                      />
                      <span>{file.name}</span>
                    </div>
                  ),
                  size: file.size,
                  type: 'file',
                  actions: '',
                }) satisfies DataType,
            ),
            ...(data?.knowledgeBaseDirectory.folders?.map(
              (folder) =>
                ({
                  id: folder.id,
                  name: folder.name,
                  size: 0,
                  type: 'folder',
                }) satisfies DataType,
            ) ?? []),
            ...(data?.knowledgeBaseDirectory.files?.map(
              (file) =>
                ({
                  id: file.id,
                  name: file.fileName,
                  size: file.size,
                  url: file.url,
                  type: 'file',
                }) satisfies DataType,
            ) ?? []),
          ]}
          rowHoverable
        />
      </Upload.Dragger>

      {createFolderModal}
    </Card>
  );
};

export default KnowledgeBase;
