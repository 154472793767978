import { Button, Card, Col, ColorPicker, Form, Input, Row } from 'antd';
import React, { useCallback, useEffect } from 'react';
import Loading from '../../components/base/Loading';
import PageHeader from '../../components/base/PageHeader';
import FileStringUpload from '../../components/form/FileStringUpload';
import {
  useGetOwnCompanyQuery,
  useUpdateCompanyMutation,
} from '../../graphql/schema';
import formItemProps from '../../helper/formItemProps';

interface FormFields {
  name: string;
  description: string;
  facts: string;
  logo: string;
  primaryColor: string;
  primaryColorText: string;
  secondaryColor: string;
  defaultGatewayId?: string | null;
}

const Company = () => {
  const [form] = Form.useForm<FormFields>();

  const { data, loading } = useGetOwnCompanyQuery();
  const [update] = useUpdateCompanyMutation();

  const handleUpdate = useCallback(
    async (values: FormFields) => {
      if (!data?.ownCompany.id) return;

      await update({
        variables: {
          id: data.ownCompany.id,
          dto: {
            name: values.name,
            config: {
              description: values.description,
              facts: values.facts,
              logo: values.logo,
              primaryColor: values.primaryColor,
              primaryColorText: values.primaryColorText,
              secondaryColor: values.secondaryColor,
              defaultGatewayId: values.defaultGatewayId,
            },
          },
        },
        refetchQueries: ['GetCompanies'],
      });
    },
    [update, data],
  );

  useEffect(() => {
    if (data?.ownCompany) {
      form.setFieldsValue({
        name: data.ownCompany.name,
        description: data.ownCompany.config.description ?? '',
        facts: data.ownCompany.config.facts ?? '',
        logo: data.ownCompany.config.logo ?? undefined,
        primaryColor: data.ownCompany.config.primaryColor ?? '',
        primaryColorText: data.ownCompany.config.primaryColorText ?? '',
        secondaryColor: data.ownCompany.config.secondaryColor ?? '',
        defaultGatewayId: data.ownCompany.config.defaultGatewayId ?? undefined,
      });
    }
  }, [data, form]);

  if (loading) return <Loading />;

  return (
    <Card>
      <PageHeader
        breadcrumbs={[
          {
            label: data?.ownCompany.name ?? 'Firma',
          },
          {
            label: 'Einstellungen',
          },
        ]}
      />

      <Form layout="vertical" form={form} onFinish={handleUpdate}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('name', 'Name', true)}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('description', 'Beschreibung', false)}>
              <Input.TextArea placeholder="Beschreibung" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('facts', 'Fakten', false)}>
              <Input.TextArea placeholder="Fakten zur Firma (z.B. Anzahl der Mitarbeiter, ...)" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item {...formItemProps('logo', 'Logo', true)}>
          <FileStringUpload />
        </Form.Item>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              {...formItemProps('primaryColor', 'Primäre Farbe', true)}
              style={{ marginBottom: 10 }}
              getValueFromEvent={(color) => {
                return `#${color.toHex()}`;
              }}
            >
              <ColorPicker showText />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              {...formItemProps('primaryColorText', 'Primäre Textfarbe', true)}
              style={{ marginBottom: 10 }}
              getValueFromEvent={(color) => {
                return `#${color.toHex()}`;
              }}
            >
              <ColorPicker showText />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              {...formItemProps('secondaryColor', 'Hintergrundfarbe', true)}
              style={{ marginBottom: 10 }}
              getValueFromEvent={(color) => {
                return `#${color.toHex()}`;
              }}
            >
              <ColorPicker showText />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Button type="primary" htmlType="submit">
              Speichern
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Company;
