import { faFiles, faPencil } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
} from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Columns } from '../../components/admin/Table';
import TableWithDrawer from '../../components/admin/TableWithDrawer';
import PageHeader from '../../components/base/PageHeader';
import useCompany from '../../context/company/useCompany';
import {
  KnowledgeBase,
  KnowledgeBaseAccessType,
  KnowledgeBaseType,
  KnowledgeBasesQuery,
  KnowledgeBasesQueryVariables,
  useCreateKnowledgeBaseMutation,
  useDeleteKnowledgeBaseMutation,
  useKnowledgeBasesQuery,
  useUpdateKnowledgeBaseMutation,
} from '../../graphql/schema';
import formItemProps from '../../helper/formItemProps';

const columns: Columns<KnowledgeBase> = {
  name: 'Name',
  type: {
    label: 'Typ',
    render: (val) => {
      if (val === KnowledgeBaseType.FILES) return 'Dateien';
      if (val === KnowledgeBaseType.EXTERNAL) return 'Extern';
    },
  },
  accessType: {
    label: 'Zugriff',
    render: (val) => {
      if (val === KnowledgeBaseAccessType.ALL) return 'Alle Benutzer';
      if (val === KnowledgeBaseAccessType.USER_LIST)
        return 'Festgelegte Benutzer';
    },
  },
};

interface FormFields {
  name: string;
  type: KnowledgeBaseType;
  accessType: KnowledgeBaseAccessType;
}

const KnowledgeBases = () => {
  const { company } = useCompany();
  const navigate = useNavigate();

  const [create] = useCreateKnowledgeBaseMutation();
  const [update] = useUpdateKnowledgeBaseMutation();
  const [remove] = useDeleteKnowledgeBaseMutation();

  const handleCreate = useCallback(
    async (values: FormFields) => {
      await create({
        variables: {
          dto: {
            name: values.name,
            type: values.type,
            accessType: values.accessType,
          },
        },
      });
    },
    [create],
  );

  const handleUpdate = useCallback(
    async (id: string, values: FormFields) => {
      await update({
        variables: {
          id,
          dto: {
            name: values.name,
            type: values.type,
            accessType: values.accessType,
          },
        },
      });
    },
    [update],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
      });
    },
    [remove],
  );

  const formRenderer = (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('name', 'Name', true)}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('type', 'Typ', true)}>
              <Select
                options={[
                  {
                    value: KnowledgeBaseType.FILES,
                    label: 'Dateien',
                  },
                  {
                    value: KnowledgeBaseType.EXTERNAL,
                    label: 'Extern',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps('accessType', 'Zugriff', true)}>
              <Select
                options={[
                  {
                    value: KnowledgeBaseAccessType.ALL,
                    label: 'Alle Benutzer',
                  },
                  {
                    value: KnowledgeBaseAccessType.USER_LIST,
                    label: 'Festgelegte Benutzer',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Card>
      <PageHeader
        breadcrumbs={[
          {
            label: company?.name ?? 'Firma',
          },
          {
            label: 'Wissensdatenbanken',
          },
        ]}
      />

      <TableWithDrawer<
        FormFields,
        KnowledgeBase,
        KnowledgeBasesQuery,
        KnowledgeBasesQueryVariables
      >
        query={useKnowledgeBasesQuery}
        columns={columns}
        editFormRenderer={formRenderer}
        singleItemTitle="Wissensdatenbank"
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        prepareFormValues={(item) => ({
          name: item.name ?? '',
          type: item.type ?? KnowledgeBaseType.FILES,
          accessType: item.accessType ?? KnowledgeBaseAccessType.ALL,
        })}
        rowActions={(item, editRow) => (
          <div>
            {item.type === KnowledgeBaseType.FILES && (
              <Button
                type="text"
                onClick={() =>
                  navigate(`/companyAdmin/knowledgeBase/${item.id}`)
                }
              >
                <FontAwesomeIcon icon={faFiles} />
              </Button>
            )}
            <Button type="text" onClick={editRow}>
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          </div>
        )}
      />
    </Card>
  );
};

export default KnowledgeBases;
