import {
  faBoxes,
  faBrain,
  faBuilding,
  faCogs,
  faFolder,
  faProjector,
  faServer,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuProps } from 'antd';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
  {
    key: 'providerConfigs',
    label: 'Einstellungen',
    icon: <FontAwesomeIcon icon={faProjector} />,
  },
  {
    key: 'instanceConfig',
    label: 'Systemeinstellungen',
    icon: <FontAwesomeIcon icon={faServer} />,
  },
  {
    key: 'companies',
    label: 'Firmen',
    icon: <FontAwesomeIcon icon={faBuilding} />,
  },
  {
    key: 'modules',
    label: 'Module',
    icon: <FontAwesomeIcon icon={faBoxes} />,
  },
  {
    key: 'categories',
    label: 'Kategorien',
    icon: <FontAwesomeIcon icon={faFolder} />,
  },
  {
    key: 'taskDefinitions',
    label: 'Aufgaben',
    icon: <FontAwesomeIcon icon={faCogs} />,
  },
];

const AdminLayout = () => {
  const navigate = useNavigate();
  return (
    <div className="flex space-x-5 mt-4">
      <div className="w-[200px]">
        <Menu
          mode="vertical"
          items={items}
          onClick={(item) => navigate(`/admin/${item.key}`)}
        />
      </div>
      <div className="flex flex-1 w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
