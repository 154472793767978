import { faMegaphone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { sentryFeedbackIntegration } from '../../sentry';

const BugReportButton = () => {
  return (
    <div
      className="flex items-center cursor-pointer space-x-2 bg-white rounded-full px-4 h-[40px] justify-center text-sm"
      onClick={async () => {
        if (sentryFeedbackIntegration) {
          const form = await sentryFeedbackIntegration.createForm();

          form.appendToDom();
          form.open();
        }
      }}
    >
      <FontAwesomeIcon icon={faMegaphone} size="xs" />
      <span>Feedback</span>
    </div>
  );
};

export default BugReportButton;
