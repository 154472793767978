import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBuilding,
  faDatabase,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

interface NavItem {
  key: string;
  label: string;
  link: string;
  icon: IconProp;
  pathPrefix: string;
}

const items: NavItem[] = [
  {
    key: 'company',
    label: 'Einstellungen',
    link: '/companyAdmin/company',
    pathPrefix: '/companyAdmin/company',
    icon: faBuilding,
  },
  {
    key: 'users',
    label: 'Benutzer',
    link: '/companyAdmin/users',
    pathPrefix: '/companyAdmin/users',
    icon: faUser,
  },
  {
    key: 'knowledgeBases',
    label: 'Wissensdatenbanken',
    link: '/companyAdmin/knowledgeBases',
    pathPrefix: '/companyAdmin/knowledgeBase',
    icon: faDatabase,
  },
];

const CompanyAdminLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className="flex flex-row space-x-6">
      <div className="w-[300px]">
        <div className="flex flex-col space-y-2">
          {items.map((item) => (
            <div
              key={item.key}
              onClick={() => navigate(item.link)}
              className={`theme-bg-primary-hover ${pathname.startsWith(item.pathPrefix) ? 'theme-bg-primary' : ''} cursor-pointer rounded-xl p-[10px] px-[15px] flex space-x-2 items-center`}
            >
              <FontAwesomeIcon icon={item.icon} />
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full pb-5">
        <Outlet />
      </div>
    </div>
  );
};

export default CompanyAdminLayout;
