import { useAuth } from '@app/react-cognito';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { FeedbackContext } from './feedbackContext';

const FeedbackProvider = ({ children }: { children: React.ReactNode }) => {
  const { session, isLoading } = useAuth();

  useEffect(() => {
    if (isLoading) return;

    if (import.meta.env.VITE_SENTRY_DSN?.length) {
      if (session.user?.userAttributes.email) {
        Sentry.setUser({
          fullName: session.user?.userAttributes.sub,
          email: session.user?.userAttributes.email,
        });
      } else {
        Sentry.setUser({});
      }
    }
  }, [session, isLoading]);

  return (
    <FeedbackContext.Provider value={{}}>{children}</FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
