import * as Sentry from '@sentry/react';

let feedbackIntegration;

if (import.meta.env.VITE_SENTRY_DSN?.length) {
  feedbackIntegration = Sentry.feedbackIntegration({
    colorScheme: 'system',
    showBranding: false,
    showName: false,
    showEmail: false,
    buttonLabel: 'Feedback',
    submitButtonLabel: 'Senden',
    formTitle: 'Feedback geben',
    cancelButtonLabel: 'Abbrechen',
    confirmButtonLabel: 'Bestätigen',
    addScreenshotButtonLabel: 'Screenshot hinzufügen',
    removeScreenshotButtonLabel: 'Screenshot entfernen',
    emailLabel: 'E-Mail',
    isRequiredLabel: '(benötigt)',
    messageLabel: 'Beschreibung',
    messagePlaceholder: 'Was ist schiefgelaufen? Was hätte passieren sollen?',
    successMessageText: 'Vielen Dank für Dein Feedback!',
    autoInject: false,
  });

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_DOMAIN,
    integrations: [
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [400, 403, [405, 599]], // report errors for 400, 403 and 405 - 599 status codes
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      feedbackIntegration,
    ],

    // Tracing
    tracesSampleRate: 0.2, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export const sentryFeedbackIntegration = feedbackIntegration;
