import {
  faChevronDown,
  faMagicWandSparkles,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Space } from 'antd';
import { Provider, TaskDefinition } from '../../graphql/schema';

interface Props {
  taskDefinition: TaskDefinition;
  selectProvider: (provider: Provider) => void;
  selectedProvider?: Provider;
}

const ProviderSelector = ({
  taskDefinition,
  selectProvider,
  selectedProvider,
}: Props) => {
  if (!selectedProvider) return null;

  return (
    <div className="flex flex-row space-x-2">
      <Dropdown
        menu={{
          items: taskDefinition.usableProviders?.map((provider) => ({
            label: provider.label,
            key: provider.id,
            onClick: () => selectProvider(provider),
          })),
        }}
        trigger={['click']}
      >
        <Button type="text">
          <Space>
            <FontAwesomeIcon icon={faMagicWandSparkles} />
            {selectedProvider.label}
            <FontAwesomeIcon icon={faChevronDown} />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default ProviderSelector;
