import {
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Columns } from '../../components/admin/Table';
import TableWithDrawer from '../../components/admin/TableWithDrawer';
import PageHeader from '../../components/base/PageHeader';
import CompanySelect from '../../components/form/CompanySelect';
import GatewaySelect from '../../components/form/GatewaySelect';
import {
  GatewayConfig,
  GetGatewayConfigsQuery,
  GetGatewayConfigsQueryVariables,
  useCreateGatewayConfigMutation,
  useDeleteGatewayConfigMutation,
  useGetGatewayConfigsQuery,
  useGetGatewaysQuery,
  useUpdateGatewayConfigMutation,
} from '../../graphql/schema';
import formItemProps from '../../helper/formItemProps';

const columns: Columns<GatewayConfig> = {
  gatewayId: 'KI',
};

interface FormFields {
  config: any;
  companyId?: string;
  gatewayId: string;
}

const GatewayConfigs = () => {
  const [companyId, setCompanyId] = useState<string | undefined | null>(
    window.localStorage.getItem('gatewayConfigCompanyId') ?? null,
  );

  useEffect(() => {
    if (companyId) {
      window.localStorage.setItem('gatewayConfigCompanyId', companyId ?? '');
    } else {
      window.localStorage.removeItem('gatewayConfigCompanyId');
    }
  }, [companyId]);

  const { data: gateways } = useGetGatewaysQuery();

  const [create] = useCreateGatewayConfigMutation();
  const [update] = useUpdateGatewayConfigMutation();
  const [remove] = useDeleteGatewayConfigMutation();

  const handleCreate = useCallback(
    async (values: FormFields) => {
      await create({
        variables: {
          companyId: values.companyId,
          gatewayId: values.gatewayId,
          dto: {
            config: values.config,
          },
        },
        refetchQueries: ['GetGatewayConfigs', 'GetGlobalGatewayConfigs'],
      });
    },
    [create],
  );

  const handleUpdate = useCallback(
    async (_: string, values: FormFields, item: GatewayConfig) => {
      await update({
        variables: {
          companyId: item.companyId,
          gatewayId: item.gatewayId,
          dto: {
            config: values.config,
          },
        },
        refetchQueries: ['GetGatewayConfigs', 'GetGlobalGatewayConfigs'],
      });
    },
    [update],
  );

  const handleDelete = useCallback(
    async (_: string, item: GatewayConfig) => {
      await remove({
        variables: {
          companyId: item.companyId,
          gatewayId: item.gatewayId,
        },
        refetchQueries: ['GetGatewayConfigs', 'GetGlobalGatewayConfigs'],
      });
    },
    [remove],
  );

  const formRenderer = (type: 'create' | 'edit') => (form: FormInstance) => {
    return (
      <Form layout="vertical" form={form}>
        {type === 'create' && (
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item {...formItemProps('gatewayId', 'KI', true)}>
                <GatewaySelect />
              </Form.Item>
            </Col>
          </Row>
        )}

        {type === 'create' && (
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item {...formItemProps('companyId', 'Firma', false)}>
                <CompanySelect nullable />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.gatewayId !== currentValues.gatewayId
          }
        >
          {({ getFieldValue }) => {
            const gatewayId = getFieldValue('gatewayId');
            const gateway = gateways?.gateways.find((p) => p.id === gatewayId);

            if (!gateway) return <div />;

            return gateway.configFields.map((field) => (
              <Form.Item
                {...formItemProps(
                  ['config', field.name],
                  field.label,
                  !!field.required,
                )}
                key={field.name}
                valuePropName={field.type === 'BOOLEAN' ? 'checked' : undefined}
              >
                {field.type === 'STRING' && <Input />}
                {field.type === 'NUMBER' && <InputNumber />}
                {field.type === 'BOOLEAN' && <Checkbox />}
                {field.type === 'OPTIONS' && (
                  <Select
                    options={
                      field.options?.map((option) => ({
                        value: option.value,
                        label: option.label,
                      })) ?? []
                    }
                  />
                )}
              </Form.Item>
            ));
          }}
        </Form.Item>
      </Form>
    );
  };

  return (
    <Card>
      <PageHeader
        breadcrumbs={[
          {
            label: 'KI Einstellungen',
            link: '#',
          },
        ]}
      />

      <div className="flex space-x-2 items-center h-[32px] w-[300px] mb-4">
        <span>Firma:</span>
        <CompanySelect value={companyId} onChange={setCompanyId} nullable />
      </div>

      <TableWithDrawer<
        FormFields,
        GatewayConfig,
        GetGatewayConfigsQuery,
        GetGatewayConfigsQueryVariables
      >
        query={useGetGatewayConfigsQuery}
        queryVariables={{
          companyId: companyId,
        }}
        columns={columns}
        editFormRenderer={formRenderer('edit')}
        createFormRenderer={formRenderer('create')}
        singleItemTitle="Einstellungen"
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        prepareFormValues={(item) => ({
          config: item.config,
          gatewayId: item.gatewayId,
          companyId: item.companyId ?? undefined,
        })}
      />
    </Card>
  );
};

export default GatewayConfigs;
