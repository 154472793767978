import { liquid } from '@codemirror/lang-liquid';
import {
  faChevronDown,
  faChevronUp,
  faLink,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CodeMirror from '@uiw/react-codemirror';
import { Alert, Space } from 'antd';
import { Liquid, LiquidError } from 'liquidjs';
import { useCallback, useMemo, useState } from 'react';
import styles from './CodeInput.module.scss';

export interface Props {
  value?: string;
  onChange?: (value: string) => void;
  variables: any;
  defaultValue?: any;
}

const engine = new Liquid({
  strictVariables: true,
});

const pathsFromObject = (obj: any) => {
  const paths: string[] = [];

  for (const key in obj) {
    if (obj[key] && typeof obj[key] === 'object') {
      for (const path of pathsFromObject(obj[key])) {
        paths.push(`${key}.${path}`);
      }
    } else {
      paths.push(key);
    }
  }

  return paths;
};

const CodeInput = ({ value, onChange, variables }: Props) => {
  const [error, setError] = useState<string>();
  const [rendered, setRendered] = useState<string>();
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const liquidLangSupport = useMemo(() => {
    return liquid({
      variables: pathsFromObject(variables).map((variable) => ({
        label: variable,
      })),
    });
  }, [variables]);

  const parseTemplate = useCallback(async () => {
    setError(undefined);
    try {
      setRendered(await engine.parseAndRender(value ?? '', variables));
    } catch (e) {
      if (e instanceof LiquidError) {
        setError(e.message);
      }
    }
  }, [value, variables]);

  return (
    <div className="relative">
      <div className="absolute top-[-20px] right-0 text-xs">
        <a href="https://shopify.github.io/liquid/" target="_blank">
          <Space>
            <FontAwesomeIcon icon={faLink} />
            Dokumentation Template Syntax
          </Space>
        </a>
      </div>
      <CodeMirror
        className={`border rounded-sm overflow-hidden border-[#bbcfe2] ${styles.editor}`}
        value={`${value ?? ''}`}
        extensions={[liquidLangSupport]}
        basicSetup={{
          highlightActiveLine: false,
          lineNumbers: (`${value ?? ''}`.split('\n').length ?? 0) > 1,
          // autocompletion: true, // TODO
          bracketMatching: true,
          closeBrackets: true,
          // completionKeymap: true, // TODO
          highlightActiveLineGutter: false,
        }}
        editable
        onChange={onChange}
        height="auto"
        width="100%"
        onBlur={parseTemplate}
      />
      {error && <Alert message={error} type="error" />}
      <div
        className="justify-center flex text-xs items-center space-x-1 cursor-pointer"
        onClick={() => {
          setShowPreview((s) => !s);
          parseTemplate();
        }}
      >
        <FontAwesomeIcon icon={showPreview ? faChevronUp : faChevronDown} />
        <span>{showPreview ? 'Hide' : 'Show'} preview</span>
      </div>
      {showPreview && (
        <div className="text-xs border rounded-md overflow-hidden border-[#bbcfe2] p-2 bg-[#f9f9f9]">
          {rendered?.split('\n').map((line, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <div key={index}>{line}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CodeInput;
