import { Select } from 'antd';
import CodeInput, { Props } from './CodeInput';

const CodeInputBoolean = ({
  value,
  onChange,
  variables,
  defaultValue,
}: Props) => {
  return (
    <div>
      <Select
        size="small"
        options={[
          {
            label: 'True',
            value: true,
          },
          {
            label: 'False',
            value: false,
          },
          {
            label: 'Template (Must return "true" or "false")',
            value: '',
          },
        ]}
        value={
          value === undefined
            ? defaultValue
            : typeof value === 'boolean'
              ? value
              : ''
        }
        onChange={onChange}
      />
      {typeof value === 'string' && (
        <div className="mt-1">
          <CodeInput value={value} onChange={onChange} variables={variables} />
        </div>
      )}
    </div>
  );
};

export default CodeInputBoolean;
