import { UploadContentType } from '@app/shared';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, DatePicker, Input, InputNumber, Select } from 'antd';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { FormFieldDefinition } from '../../graphql/schema';
import { languages } from '../../helper/languages';
import CollapsibleCodeBlock from '../base/FormattedMessage/CollapsibleCodeBlock';
import CountryCodeSelect from '../form/CountryCodeSelect';
import CountrySelect from '../form/CountrySelect';
import LanguageCodeSelect from '../form/LanguageCodeSelect';
import LanguageSelect from '../form/LanguageSelect';
import SimpleCodeInput from '../form/SimpleCodeInput';
import Upload, { UploadReadOnly } from '../form/Upload';

interface Fields {
  [key: string]: {
    label: string;
    renderComponent: (fieldDefinition: FormFieldDefinition) => ReactElement;
    renderReadOnly: (
      value: any,
      fieldDefinition: FormFieldDefinition,
    ) => ReactElement | string;
    hasMinMax?: boolean;
    hasOptions?: boolean;
    valuePropName?: string;
    supportsDefaultValue?: boolean;
  };
}

const fields: Fields = {
  text: {
    label: 'Text field',
    renderComponent: () => <Input />,
    renderReadOnly: (value) => value,
  },
  textarea: {
    label: 'Textarea field',
    renderComponent: () => <Input.TextArea rows={5} />,
    renderReadOnly: (value) => value,
  },
  number: {
    label: 'Number field',
    renderComponent: () => <InputNumber />,
    renderReadOnly: (value) => value,
    hasMinMax: true,
  },
  boolean: {
    label: 'Checkbox',
    renderComponent: () => <Checkbox />,
    renderReadOnly: (value) =>
      value ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : (
        <FontAwesomeIcon icon={faTimes} />
      ),
    valuePropName: 'checked',
  },
  date: {
    label: 'Date',
    renderComponent: () => <DatePicker format="DD.MM.YYYY" />,
    renderReadOnly: (value) => dayjs(value).format('DD.MM.YYYY'),
  },
  file: {
    label: 'File',
    renderComponent: () => (
      <Upload allowedMimeTypes={Object.values(UploadContentType)} />
    ),
    renderReadOnly: (value) => <UploadReadOnly value={value} />,
    supportsDefaultValue: false,
  },
  image: {
    label: 'Image',
    renderComponent: () => (
      <Upload
        allowedMimeTypes={[
          'image/png',
          'image/jpg',
          'image/jpeg',
          'image/webp',
        ]}
      />
    ),
    renderReadOnly: (value) => <UploadReadOnly value={value} />,
    supportsDefaultValue: false,
  },
  files: {
    label: 'Multiple Files',
    renderComponent: () => (
      <Upload
        maxCount={10}
        allowedMimeTypes={Object.values(UploadContentType)}
      />
    ),
    renderReadOnly: (value) => <UploadReadOnly value={value} />,
    supportsDefaultValue: false,
  },
  images: {
    label: 'Multiple Images',
    renderComponent: () => (
      <Upload
        maxCount={10}
        allowedMimeTypes={[
          'image/png',
          'image/jpg',
          'image/jpeg',
          'image/webp',
        ]}
      />
    ),
    renderReadOnly: (value) => <UploadReadOnly value={value} />,
    supportsDefaultValue: false,
  },
  options: {
    label: 'Options',
    hasOptions: true,
    renderComponent: (fieldDefinition) => (
      <Select
        options={fieldDefinition.options
          ?.filter((o) => o?.value)
          .map(({ label, value }) => ({
            label: label ?? value,
            value,
          }))}
      />
    ),
    renderReadOnly: (value, fieldDefinition) =>
      fieldDefinition.options?.find((opt) => opt.value === value)?.label ?? '-',
  },
  language: {
    label: 'Language Name',
    renderComponent: () => <LanguageSelect />,
    renderReadOnly: (value) => value,
  },
  country: {
    label: 'Country Name',
    renderComponent: () => <CountrySelect />,
    renderReadOnly: (value) => value,
  },
  languageCode: {
    label: 'Language ISO Code',
    renderComponent: () => <LanguageCodeSelect />,
    renderReadOnly: (value) => (languages as any)[value] ?? '',
  },
  countryCode: {
    label: 'Country ISO Code',
    renderComponent: () => <CountryCodeSelect />,
    renderReadOnly: (value) => (languages as any)[value] ?? '',
  },
  code: {
    label: 'Code',
    renderComponent: () => <SimpleCodeInput />,
    renderReadOnly: (value) => (
      <CollapsibleCodeBlock>{value}</CollapsibleCodeBlock>
    ),
  },
};

export default fields;
