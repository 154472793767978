import { Select } from 'antd';
import React from 'react';
import { useGetGatewaysQuery } from '../../graphql/schema';

interface Props {
  value?: string;
  onChange?: (id: string) => void;
}

interface Option {
  label: string;
  value: string;
}

const GatewaySelect = ({ value, onChange }: Props) => {
  const { data, loading } = useGetGatewaysQuery();

  const options: Option[] =
    data?.gateways.map((item) => {
      return {
        label: item.label,
        value: item.id,
      };
    }) || [];

  return (
    <Select
      onChange={onChange}
      loading={loading}
      value={value}
      options={options}
      className="w-full"
    />
  );
};

export default GatewaySelect;
