import { faHouse } from '@fortawesome/pro-light-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Illustration from '../components/base/Illustration';
import Loading from '../components/base/Loading';
import PageHeader from '../components/base/PageHeader';
import ProviderSelector from '../components/tasks/ProviderSelector';
import TaskDefinitionForm from '../components/tasks/TaskDefinitionForm';
import {
  Provider,
  useCreateTaskMutation,
  useGetModulesWithCategoriesQuery,
  useGetTaskDefinitionQuery,
} from '../graphql/schema';
import uploadFormFiles from '../helper/uploadFormFiles';

const CreateTask = () => {
  const { taskDefinitionId } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useGetTaskDefinitionQuery({
    variables: {
      id: taskDefinitionId ?? '',
    },
    skip: !taskDefinitionId,
    fetchPolicy: 'no-cache',
  });

  const [createTask] = useCreateTaskMutation();

  const [selectedProvider, setSelectedProvider] = useState<Provider>();
  const [createTaskLoading, setCreateTaskLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (formValues: any) => {
      if (!selectedProvider) return;

      setCreateTaskLoading(true);

      const task = await createTask({
        variables: {
          dto: {
            taskDefinitionId: taskDefinitionId ?? '',
            gatewayId: selectedProvider.gatewayId,
          },
        },
        refetchQueries: ['GetTasks'],
      });

      window.localStorage.setItem(
        'newTaskMessage',
        JSON.stringify({
          taskId: task.data?.createTask.id,
          formValues: await uploadFormFiles(
            'task',
            formValues,
            task.data?.createTask?.fileUploadInfo,
          ),
        }),
      );

      navigate(`/task/${task.data?.createTask.id}`);
    },
    [taskDefinitionId, selectedProvider],
  );

  const { data: modules, loading: modulesLoading } =
    useGetModulesWithCategoriesQuery();

  const [module, category] = useMemo(() => {
    if (modules) {
      for (const m of modules.modulesWithCategories) {
        if (m.categories) {
          for (const c of m.categories) {
            if (c.id === data?.taskDefinition.categoryId) {
              return [m, c];
            }
          }
        }
      }
    }

    return [undefined, undefined];
  }, [data, modules]);

  useEffect(() => {
    if (
      data?.taskDefinition.usableProviders &&
      data?.taskDefinition.usableProviders.length > 0
    ) {
      setSelectedProvider(data?.taskDefinition.usableProviders[0]);
    }
  }, [data]);

  if (loading || modulesLoading) return <Loading />;

  return (
    <div>
      <PageHeader
        breadcrumbs={[
          {
            icon: faHouse,
            link: '/',
          },
          {
            label: module?.title,
            link: `/module/${module?.id}`,
          },
          {
            label: category?.title,
            link: `/module/${module?.id}`,
          },
          {
            label: data?.taskDefinition.title,
            link: '#',
          },
        ]}
      />
      {data?.taskDefinition && (
        <>
          <div className="flex space-x-4 mb-3 relative">
            <div className="w-1/4 max-w-[180px]">
              <Illustration
                id={data.taskDefinition.illustration}
                className="w-full"
              />
            </div>
            <div>
              <div className="text-lg font-semibold">
                {data.taskDefinition.title}
              </div>
              <p className="text-sm">{data.taskDefinition.description}</p>
            </div>
            {data?.taskDefinition.usableProviders &&
              data.taskDefinition.usableProviders.length > 1 && (
                <div className="absolute top-0 right-0">
                  <ProviderSelector
                    taskDefinition={data.taskDefinition}
                    selectProvider={setSelectedProvider}
                    selectedProvider={selectedProvider}
                  />
                </div>
              )}
          </div>

          <TaskDefinitionForm
            schema={data?.taskDefinition.formDefinition}
            onSubmit={handleSubmit}
            saveButtonLabel="Entwurf erstellen"
            loading={createTaskLoading}
          />
        </>
      )}
    </div>
  );
};

export default CreateTask;
