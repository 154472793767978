import { Select } from 'antd';
import React from 'react';
import {
  KnowledgeBaseAccessType,
  useKnowledgeBasesQuery,
} from '../../graphql/schema';

interface Props {
  value?: string[] | null;
  onChange?: (value: string[]) => void;
}

interface Option {
  label: string;
  value: string;
}

const KnowledgeBaseSelect = ({ value, onChange }: Props) => {
  const { data, loading } = useKnowledgeBasesQuery();

  const options: Option[] =
    data?.knowledgeBases
      .filter((item) => item.accessType === KnowledgeBaseAccessType.USER_LIST)
      .map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      }) || [];

  return (
    <Select
      mode="multiple"
      onChange={onChange}
      loading={loading}
      value={value ?? []}
      options={options}
      className="w-full"
    />
  );
};

export default KnowledgeBaseSelect;
