import { promptPhrases } from '@app/shared';
import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Tabs, notification } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Fab from '../../components/admin/Fab';
import Loading from '../../components/base/Loading';
import PageHeader from '../../components/base/PageHeader';
import FormBuilder from '../../components/formBuilder/FormBuilder';
import MessageTemplateBuilder, {
  MessageTemplateBuilderConfig,
} from '../../components/taskDefinitionBuilder/MessageTemplateBuilder';
import TaskDefinitionFiles from '../../components/taskDefinitionBuilder/TaskDefinitionFiles';
import useCompany from '../../context/company/useCompany';
import {
  FormFieldDefinition,
  useGetTaskDefinitionFilesQuery,
  useGetTaskDefinitionQuery,
  useOwnUserQuery,
  useUpdateTaskDefinitionMutation,
} from '../../graphql/schema';

const TaskDefinition = () => {
  const { id } = useParams();
  const { company } = useCompany();
  const { data: ownUser } = useOwnUserQuery();

  const [formDefinition, setFormDefinition] = useState<FormFieldDefinition[]>(
    [],
  );
  const [settingsFormDefinition, setSettingsFormDefinition] = useState<
    FormFieldDefinition[]
  >([]);
  const [config, setConfig] = useState<MessageTemplateBuilderConfig>({
    followUpPrompts: [],
    messageTemplate: {},
    settingsTemplate: {},
  });
  const [formsData, setFormsData] = useState<{
    form: any;
    settings: any;
  }>({
    form: {},
    settings: {},
  });

  const [updateTaskDefinition] = useUpdateTaskDefinitionMutation();

  const { data, loading } = useGetTaskDefinitionQuery({
    variables: {
      id: id ?? '',
    },
    skip: !id,
    fetchPolicy: 'no-cache',
  });

  const {
    data: files,
    loading: filesLoading,
    refetch: reloadFiles,
  } = useGetTaskDefinitionFilesQuery({
    variables: {
      taskDefinitionId: id ?? '',
    },
    skip: !id,
  });

  useEffect(() => {
    if (!data?.taskDefinition) return;

    if (data.taskDefinition.formDefinition) {
      setFormDefinition(
        data?.taskDefinition.formDefinition.map(({ __typename, ...item }) => ({
          ...item,
        })),
      );
    }
    if (data.taskDefinition.settingsFormDefinition) {
      setSettingsFormDefinition(
        data?.taskDefinition.settingsFormDefinition.map(
          ({ __typename, ...item }) => ({
            ...item,
          }),
        ),
      );
    }
    setConfig({
      messageTemplate: data?.taskDefinition.messageTemplate ?? {},
      settingsTemplate: data?.taskDefinition.settingsTemplate ?? {},
      followUpPrompts: data?.taskDefinition.followUpPrompts ?? [],
    });
  }, [data]);

  const handleSave = useCallback(async () => {
    await updateTaskDefinition({
      variables: {
        id: id ?? '',
        dto: {
          formDefinition,
          settingsFormDefinition,
          messageTemplate: config.messageTemplate,
          settingsTemplate: config.settingsTemplate,
          followUpPrompts: config.followUpPrompts?.map((followUpPrompt) => ({
            label: followUpPrompt.label,
            prompt: followUpPrompt.prompt,
          })),
        },
      },
    });

    notification.success({
      message: 'Die Änderungen wurden gespeichert.',
    });
  }, [
    id,
    config,
    formDefinition,
    settingsFormDefinition,
    updateTaskDefinition,
  ]);

  const handleChangeData = useCallback((type: 'form' | 'settings') => {
    return (newData: any) => {
      setFormsData((old) => ({ ...old, [type]: newData }));
    };
  }, []);

  const formVariables = useMemo(() => {
    const vars: any = {
      form: {},
      settings: {},
      company: {
        name: company?.name ?? '',
        description: company?.config.description ?? '',
        facts: company?.config.facts ?? '',
      },
      user: {
        firstName: ownUser?.ownUser.firstName ?? '',
        lastName: ownUser?.ownUser.lastName ?? '',
      },
      files: files?.taskDefinitionFiles?.map((file) => file.id) ?? [],
      phrases: promptPhrases,
    };

    const convertValue = (value: any): any => {
      if (value instanceof File) {
        return value.name;
      }
      if (Array.isArray(value)) {
        return value.map((v) => convertValue(v));
      }
      return value;
    };

    for (const field of formDefinition) {
      vars.form[field.name] = convertValue(
        formsData.form[field.name] ?? field.default ?? null,
      );
    }

    for (const field of settingsFormDefinition) {
      vars.settings[field.name] = convertValue(
        formsData.settings[field.name] ?? field.default ?? null,
      );
    }

    return vars;
  }, [
    formDefinition,
    settingsFormDefinition,
    formsData,
    company,
    ownUser,
    files,
  ]);

  if (loading || !data?.taskDefinition) return <Loading />;

  return (
    <div className="w-full">
      <Card>
        <PageHeader
          breadcrumbs={[
            {
              label: 'Aufgaben',
              link: '/admin/taskDefinitions',
            },
            {
              label: data.taskDefinition.title ?? 'Aufgabe',
              link: '#',
            },
          ]}
        />
      </Card>
      <Tabs
        type="card"
        items={[
          {
            key: 'formDefinition',
            label: 'Nutzereingabe',
            closable: false,
            children: (
              <FormBuilder
                schema={formDefinition}
                onChangeSchema={setFormDefinition}
                onChangeData={handleChangeData('form')}
              />
            ),
          },
          {
            key: 'settingsFormDefinition',
            label: 'Firmenspezifische Einstellungen',
            closable: false,
            children: (
              <FormBuilder
                schema={settingsFormDefinition}
                onChangeSchema={setSettingsFormDefinition}
                onChangeData={handleChangeData('settings')}
              />
            ),
          },
          {
            key: 'files',
            label: 'Dateien',
            closable: false,
            children: (
              <TaskDefinitionFiles
                taskDefinition={data.taskDefinition}
                files={files?.taskDefinitionFiles ?? []}
                filesLoading={filesLoading}
                reloadFiles={reloadFiles}
              />
            ),
          },
          {
            key: 'message',
            label: 'Prompt',
            closable: false,
            children: (
              <MessageTemplateBuilder
                taskDefinition={data.taskDefinition}
                capability={data.taskDefinition.capability}
                config={config}
                onConfigChange={setConfig}
                variables={formVariables}
                messageSchema={formDefinition}
                settingsSchema={settingsFormDefinition}
                files={files?.taskDefinitionFiles ?? []}
              />
            ),
          },
        ]}
        renderTabBar={(props, DefaultTabBar) => (
          <div className="bg-white px-4 mt-[-30px] mb-3 z-20 border-x-[1px] border-x-[#f0f0f0] border-b-[1px] border-b-[#f0f0f0]">
            <DefaultTabBar {...props} />
          </div>
        )}
      />

      <Fab
        onClick={handleSave}
        icon={<FontAwesomeIcon icon={faFloppyDisk} />}
        title="Speichern"
      />
    </div>
  );
};

export default TaskDefinition;
