import { Card, Form, Space } from 'antd';
import React, { useCallback, useEffect } from 'react';
import {
  File as ApiFile,
  Capability,
  FollowUpPrompt,
  FormFieldDefinition,
  TaskDefinition,
} from '../../graphql/schema';
import FollowUpPrompts from './FollowUpPrompts';
import TemplateFormField from './TemplateFormField';

export interface MessageTemplateBuilderConfig {
  messageTemplate: any;
  settingsTemplate: any;
  followUpPrompts: FollowUpPrompt[];
}

interface Props {
  taskDefinition: TaskDefinition;
  capability?: Capability | null;
  config: MessageTemplateBuilderConfig;
  onConfigChange: (config: MessageTemplateBuilderConfig) => void;
  variables: any;
  messageSchema: FormFieldDefinition[];
  settingsSchema: FormFieldDefinition[];
  files: ApiFile[];
}

const MessageTemplateBuilder = ({
  taskDefinition,
  capability,
  config,
  onConfigChange,
  variables,
  messageSchema,
  settingsSchema,
  files,
}: Props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const fieldValues = {
      messageTemplate: config.messageTemplate,
      settingsTemplate: config.settingsTemplate,
      followUpPrompts: config.followUpPrompts,
    };

    capability?.messageTemplateFields.forEach((field) => {
      if (
        fieldValues.messageTemplate &&
        fieldValues.messageTemplate[field.name] === undefined &&
        field.default
      ) {
        fieldValues.messageTemplate[field.name] = field.default;
      }
    });

    capability?.settingsTemplateFields.forEach((field) => {
      if (
        fieldValues.settingsTemplate &&
        fieldValues.settingsTemplate[field.name] === undefined &&
        field.default
      ) {
        fieldValues.settingsTemplate[field.name] = field.default;
      }
    });

    form.setFieldsValue(fieldValues);
  }, [config, form, capability]);

  const handleChange = useCallback(() => {
    onConfigChange(form.getFieldsValue());
  }, [form, onConfigChange]);

  const { form: _, ...variablesWithoutForm } = variables;

  return (
    <Form form={form} layout="vertical" onBlur={handleChange}>
      <Space direction="vertical" className="w-full mb-[100px]">
        <Card title="Prompt Vorlage">
          {capability?.messageTemplateFields.map((field) => (
            <TemplateFormField
              key={field.name}
              name={['messageTemplate', field.name]}
              field={field}
              variables={variables}
              messageSchema={messageSchema}
              settingsSchema={settingsSchema}
              files={files}
            />
          ))}
        </Card>

        <Card title="Einstellungen Vorlage">
          {capability?.settingsTemplateFields.map((field) => (
            <TemplateFormField
              key={field.name}
              name={['settingsTemplate', field.name]}
              field={field}
              variables={variablesWithoutForm}
              messageSchema={messageSchema}
              settingsSchema={settingsSchema}
              files={files}
            />
          ))}
        </Card>

        <Card title="Folge-Prompts">
          <FollowUpPrompts variables={variablesWithoutForm} />
        </Card>
      </Space>
    </Form>
  );
};

export default MessageTemplateBuilder;
