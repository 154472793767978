import {
  Button,
  Card,
  Col,
  ColorPicker,
  Form,
  Input,
  Row,
  notification,
} from 'antd';
import React, { useCallback, useEffect } from 'react';
import Loading from '../../components/base/Loading';
import PageHeader from '../../components/base/PageHeader';
import FileStringUpload from '../../components/form/FileStringUpload';
import GatewaySelect from '../../components/form/GatewaySelect';
import {
  useGetInstanceConfigQuery,
  useSetInstanceConfigMutation,
} from '../../graphql/schema';
import formItemProps from '../../helper/formItemProps';

interface FormFields {
  name?: string | null;
  logo?: string | null;
  primaryColor?: string | null;
  primaryColorText?: string | null;
  secondaryColor?: string | null;
  defaultGatewayId?: string | null;
}

const InstanceConfig = () => {
  const { data, loading } = useGetInstanceConfigQuery();
  const [setConfig] = useSetInstanceConfigMutation();

  const [form] = Form.useForm<FormFields>();

  useEffect(() => {
    if (data?.instanceConfig) {
      form.setFieldsValue(data.instanceConfig);
    }
  }, [data]);

  const handleSave = useCallback(
    async (values: FormFields) => {
      await setConfig({
        variables: {
          config: {
            name: values.name,
            logo: values.logo,
            primaryColor: values.primaryColor,
            primaryColorText: values.primaryColorText,
            secondaryColor: values.secondaryColor,
            defaultGatewayId: values.defaultGatewayId,
          },
        },
      });
      notification.success({
        message: 'Die Einstellungen wurden gespeichert.',
      });
    },
    [setConfig],
  );

  return (
    <Card>
      <PageHeader
        breadcrumbs={[
          {
            label: 'Globale Einstellungen',
            link: '#',
          },
        ]}
      />

      {loading && <Loading />}

      {!loading && (
        <Form layout="vertical" form={form} onFinish={handleSave}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item {...formItemProps('name', 'Name', false)}>
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item {...formItemProps('logo', 'Logo', false)}>
            <FileStringUpload />
          </Form.Item>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                {...formItemProps('primaryColor', 'Primäre Farbe', false)}
                style={{ marginBottom: 10 }}
                getValueFromEvent={(color) => {
                  return `#${color.toHex()}`;
                }}
              >
                <ColorPicker showText />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                {...formItemProps(
                  'primaryColorText',
                  'Primäre Textfarbe',
                  false,
                )}
                style={{ marginBottom: 10 }}
                getValueFromEvent={(color) => {
                  return `#${color.toHex()}`;
                }}
              >
                <ColorPicker showText />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                {...formItemProps('secondaryColor', 'Hintergrundfarbe', false)}
                style={{ marginBottom: 10 }}
                getValueFromEvent={(color) => {
                  return `#${color.toHex()}`;
                }}
              >
                <ColorPicker showText />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                {...formItemProps(
                  'defaultGatewayId',
                  'Standard Gateway',
                  false,
                )}
              >
                <GatewaySelect />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Button type="primary" htmlType="submit">
                Speichern
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default InstanceConfig;
